<template>
  <div class="app-container">
    <div class="centerButton-container">
      <el-button
        style="margin-top: 50px; width: 400px; height: 40px"
        type="primary"
        @click="onUploadClick"
        >确认推单</el-button
      >
      <input
        id="upload"
        hidden
        type="file"
        data-num="1"
        value
        @change="onUpload"
      />

      <div class="scrollUp" >
        <ul id="scrolList"  v-infinite-scroll="load">
          <li v-for="item in message" :key="item.paymentNo">
            订单编号：{{ item.paymentNo }}
            <br />
            信息：{{ item.msg }}
          </li>
        </ul>
        <ul id="scrolBlack"></ul>
      </div>
    </div>
  </div>
</template>

<script>
import { pushOrder } from "@/api/order";
export default {
  data() {
    return {
      message: [
      ],
    };
  },
  methods: {
    onUploadClick() {
      document.getElementById("upload").click();
    },
    onUpload(e) {
      if (e.target.files[0].type != null) {
        // const that = this;
        // const types = ["image/jpeg", "image/jpg", "image/png"];
        // const isImageType = types.includes(e.target.files[0].type);
        // if (!isImageType) {
        //   that.$message.error("上传图片只能是 JPG、JPEG、PNG 格式!");
        //   return false;
        // }
        this.uploadFormData(e);
      }
    },
    uploadFormData(e) {
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      document.getElementById("upload").value = "";
      this.message = [];
      pushOrder(formData).then((_) => {
        if (_.data.errorMsg) {
          this.$message.error(_.data.errorMsg);
        } else {
          this.$message.success("上传成功!");
          this.message = _.data;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.centerButton-container {
  margin: 10% auto;
  width: 400px;
}
.scrollUp {
  margin-top: 20px;
  max-height: 200px;
  overflow-y:auto;
  overflow-x: hidden;
  ul {
    list-style: none;
    li {
      margin-bottom: 8px;
    }
  }
}

</style>